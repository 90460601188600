import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getexecutiondetails",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#getexecutiondetails",
        "aria-label": "getexecutiondetails permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getExecutionDetails`}</h1>
    <p>{`The function formats trade data for execution against the relevant Starswap exchange.`}</p>
    <h2 {...{
      "id": "function-signature",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#function-signature",
        "aria-label": "function signature permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Function Signature`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export function getExecutionDetails(
  trade: TradeDetails,
  maxSlippage?: number,
  deadline?: number,
  recipient?: string
): ExecutionDetails
`}</code></pre>
    <h2 {...{
      "id": "input-parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#input-parameters",
        "aria-label": "input parameters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Input Parameters`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`trade`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TradeDetails`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The trade to execute.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`maxSlippage?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The maximum slippage to allow, in basis points. Defaults to 200 `}{`(`}{`2%`}{`)`}{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`When the transaction will expire. Defaults to 10 minutes in the future.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`recipient?`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An optional recipient address. Defaults to the `}<inlineCode parentName="td">{`msg.sender`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "example-usage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#example-usage",
        "aria-label": "example usage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Usage`}</h2>
    <p>{`Method arguments are returned as one of: `}<inlineCode parentName="p">{`BigNumber`}</inlineCode>{`, `}<inlineCode parentName="p">{`number`}</inlineCode>{`, or `}<inlineCode parentName="p">{`string`}</inlineCode>{`. `}<inlineCode parentName="p">{`BigNumber`}</inlineCode>{`s are large number objects, `}<inlineCode parentName="p">{`numbers`}</inlineCode>{` are small numbers in base 10, and `}<inlineCode parentName="p">{`string`}</inlineCode>{`s are addresses.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const tradeDetails: TradeDetails = tradeExactEthForTokensWithData(reserves, '1000000000000000000')

const executionDetails: ExecutionDetails = await getExecutionDetails(tradeDetails)

/*
{
  // the address of the relevant exchange
  exchangeAddress: 0x09cabEC1eAd1c0Ba254B09efb3EE13841712bE14,

  // the name of the method that must be called
  methodName: "ethToTokenSwapInput",

  // the id of the method name
  methodId: "0xf39b5b9b",

  // the ether value that must be sent with the transaction
  value: <BigNumber>,

  // method arguments as an array
  methodArguments: MethodArgument[]
}
*/
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      